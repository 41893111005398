import { template as template_60c9971d6cb3431dbaf236e7df989900 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const ActionList = template_60c9971d6cb3431dbaf236e7df989900(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
