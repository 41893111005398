import { template as template_b02bf86d23de459e9bf8c48845949777 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b02bf86d23de459e9bf8c48845949777(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
