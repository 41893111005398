import { template as template_a7e4ac51caa64e8b9ee65107b19588bb } from "@ember/template-compiler";
const SidebarSectionMessage = template_a7e4ac51caa64e8b9ee65107b19588bb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
